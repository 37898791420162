<script setup>
import 'tippy.js/dist/tippy.css';
import { directive as tippyDirective } from 'vue-tippy';

const vTippy = tippyDirective;

const attrs = useAttrs();

defineProps({
    title: String,
    enabled: {
        type: Boolean,
        default: true,
    },
    element: {
        type: String,
        default: 'div',
    },
});
</script>
<template>
    <component
        :is="element"
        v-if="title && enabled"
        v-tippy="{
            content: title,
            placement: 'top',
        }"
        v-bind="attrs"
    >
        <slot />
    </component>
    <slot v-else v-bind="attrs" />
</template>
